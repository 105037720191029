import React from 'react';
import { Link } from 'react-router-dom';

import { generateId, formatDate } from '../../utilities/generic/Common.js'
import { safeStringComparison } from '../../utilities/generic/Forms.js'

import { directionHandler, directionPluralHandler, directionCheckHandler, safeSubstringFuzzyComparison, safeSubstringActiveComparison, splitAlternativeNameSubstringComparison } from '../../utilities/generic/Forms.js'

export function backgroundMatchPerson(criteria, _this) {
  // search for a matching person in the database if person data has not already been assigned by autoComplete
  let person = "";
  let people = JSON.parse(localStorage.getItem("people"))
  for (const potentialPerson of people) {
    if (safeStringComparison(potentialPerson.first_name, _this.state.first_name) &&
        safeStringComparison(potentialPerson.last_name, _this.state.last_name) &&
        safeStringComparison(potentialPerson.email, _this.state.email) &&
        safeStringComparison(potentialPerson.phone, _this.state.phone)
        ) {
      person = potentialPerson
      if (criteria === "covid_pass_expiry_date" && (new Date(person.covid_pass_expiry_date) < new Date().setHours(0,0,0,0) || person.covid_pass_exemption === true)) {
        // stop looking for matches if a match does not require a covid QR scan
        break
      }
      if (criteria === "feedback_answer_times" && (person.feedback_answer_times !== null)) {
        // stop looking for matches if a match has feedback answer times
        break
      }
    }
  }
  if (typeof person === "string") {
    person = {
      covid_pass_expiry_date: null,
      custom_attributes: [],
      feedback_answer_times: null
    }
  }
  return person
}

export function collectPeopleByIds(person, checkedInPeopleIds) {
  if (checkedInPeopleIds.includes(person.id)) {
    return person
  }
}

export function collectPeopleByValue(person, key, value) {
  var personValue = person[key]
  if (personValue === value) {
    return person
  }
}

export function collectValues(person, key, length) {
  if (person[key] !== null) {
    var partialPersonValue = person[key].substr(0, length)
    return partialPersonValue
  }
}

export function collectPeopleByType(_this) {
  let collectPeopleByType;

  let typeOfPerson = _this.props.location.state.profile.type_of_person

  let people = JSON.parse(localStorage.people);

  if (_this.state.direction === "leaving") {
    let checkedInPeopleIds = JSON.parse(localStorage.checked_in_people);

    // first, collect people that are logged in
    let collectionOfCheckedInPeople = people.filter(person => collectPeopleByIds(person, checkedInPeopleIds))

    // then collect people based on type of person
    collectPeopleByType = collectionOfCheckedInPeople.filter(person => collectPeopleByValue(person, "type_of_person", typeOfPerson));
  }
  else {
    // or otherwise first, collect people based on type of person
    collectPeopleByType = people.filter(person => collectPeopleByValue(person, "type_of_person", typeOfPerson));
  }

  return collectPeopleByType;
}

export function checkMatchingCharacters(value, personValue) {
  if (value.toLowerCase() === personValue.toLowerCase()) {
    return true;
  }
}

export function eligiblePlannedEvent(planned_event, currentTime) {
  const start_time = new Date(planned_event.start_time)
  const end_time = new Date(planned_event.end_time)

  // allow events that will be ongoing up to 8 hours before or 8 hours from now
  return (new Date(start_time.setHours(start_time.getHours() - 8)) < currentTime && new Date(end_time.setHours(end_time.getHours() + 8) > currentTime))
}

export function findPerson(people, partialValue, key, length) {
  for (var i = 0; i < people.length; i++) {
    let person = people[i]

    if (person[key] !== null && person[key].substr(0, length) === partialValue) {
      return person;
    }
  }
}

export function findPlannedEvents(person) {
  const planned_events = JSON.parse(localStorage.getItem("planned_events")) || []

  let arriving_planned_events = planned_events.filter(planned_event => planned_event.arriving_person_ids.includes(person.id))
  let hosting_planned_events = planned_events.filter(planned_event => planned_event.host_person_ids.includes(person.id))

  const currentTime = new Date()

  if (arriving_planned_events.length > 0) {
    arriving_planned_events = arriving_planned_events.filter(arriving_planned_event => eligiblePlannedEvent(arriving_planned_event, currentTime))
  }

  if (hosting_planned_events.length > 0) {
    hosting_planned_events = hosting_planned_events.filter(hosting_planned_event => eligiblePlannedEvent(hosting_planned_event, currentTime))
  }

  if (arriving_planned_events.length + hosting_planned_events.length > 0) {
    let people = JSON.parse(localStorage.getItem("people"))

    hosting_planned_events.forEach((hosting_planned_event) => {
      let first_arriving = people.find(person => person.id === hosting_planned_event.arriving_person_ids[0])

      hosting_planned_event.first_arriving = first_arriving && first_arriving.full_name
    })
    arriving_planned_events.forEach((arriving_planned_event) => {
      let first_host = people.find(person => person.id === arriving_planned_event.host_person_ids[0])

      arriving_planned_event.first_host = first_host && first_host.full_name
    })
  }


  return [arriving_planned_events, hosting_planned_events]
}

export function selectPersonThroughAutocompletion(name, value, _this) {
  if (value.length < 3) {
    return;
  }

  const collectionOfPeopleByType = collectPeopleByType(_this);

  // count how many matches we got (excluding the previously rejected autocompletePerson) and,
  // if there's only one match, return that person
  let potentialMatches = [];
  collectionOfPeopleByType.forEach((person) => {
    if (name === "first_name") {
      // Check `first_name` and `alternate_name`
      if (safeSubstringFuzzyComparison(person["first_name"], value) && JSON.stringify(person) !== JSON.stringify(_this.state.rejectedAutocompletePerson)) {
        potentialMatches.push(person);
        return
      }

      if (splitAlternativeNameSubstringComparison(person["alternate_name"], person["display_name"], value) && JSON.stringify(person) !== JSON.stringify(_this.state.rejectedAutocompletePerson)) {
        potentialMatches.push(person);
        return
      }
    }
    else {
      // Only check `[name]` (`last_name`, `phone`, or `email`)
      if (safeSubstringFuzzyComparison(person[name], value) && JSON.stringify(person) !== JSON.stringify(_this.state.rejectedAutocompletePerson)) {
        potentialMatches.push(person);
      }
    }
  });

  // if there's more than one match, use other fields to narrow down further, only excluding active mismatches
  potentialMatches = potentialMatches.filter((potentialMatch) => (
    (
      safeSubstringFuzzyComparison(potentialMatch.first_name, _this.state.first_name) ||
      splitAlternativeNameSubstringComparison(potentialMatch.alternate_name, potentialMatch.display_name, _this.state.first_name)
    ) &&
    safeSubstringFuzzyComparison(potentialMatch.last_name, _this.state.last_name) &&
    safeSubstringFuzzyComparison(potentialMatch.email, _this.state.email) &&
    safeSubstringFuzzyComparison(potentialMatch.phone, _this.state.phone)
  ))
  // filter out any potential matches that don't have at least 1 active match on a filled field
  potentialMatches = potentialMatches.filter((potentialMatch) => (
    (_this.state.first_name.trim().length > 0 && safeSubstringActiveComparison(potentialMatch.first_name, _this.state.first_name)) ||
    (_this.state.first_name.trim().length > 0 && splitAlternativeNameSubstringComparison(potentialMatch.alternate_name, potentialMatch.display_name, _this.state.first_name)) ||
    (_this.state.last_name.trim().length > 0 && safeSubstringActiveComparison(potentialMatch.last_name, _this.state.last_name)) ||
    (_this.state.email.trim().length > 0 && safeSubstringActiveComparison(potentialMatch.email, _this.state.email)) ||
    (_this.state.phone.trim().length > 0 && safeSubstringActiveComparison(potentialMatch.phone, _this.state.phone))
  ))

  // if there's still more that one match, filter again using only active matches for occupied fields
  if (potentialMatches.length > 1) {
    potentialMatches = potentialMatches.filter((potentialMatch) => ((
      (
        safeSubstringActiveComparison(potentialMatch.first_name, _this.state.first_name) ||
        splitAlternativeNameSubstringComparison(potentialMatch.alternate_name, potentialMatch.display_name, _this.state.first_name)
      ) &&
      safeSubstringActiveComparison(potentialMatch.last_name, _this.state.last_name) &&
      safeSubstringActiveComparison(potentialMatch.email, _this.state.email) &&
      safeSubstringActiveComparison(potentialMatch.phone, _this.state.phone)
    )))
  }

  // return if it's been narrowed to one
  if (potentialMatches.length === 1) {
    let person = potentialMatches[0]

    return person
  }
}

export function handleAutocomplete(event) {
  let value = event.target.value;

  if (value === "yes") {
    const person = this.state.autocompletePerson
    const custom_attributes = {}

    person.custom_attributes && person.custom_attributes.forEach((person_custom_attribute) => {
      custom_attributes[person_custom_attribute.id] = person_custom_attribute.value
    })

    this.setState({
      first_name: person.first_name,
      last_name: person.last_name,
      email: person.email,
      phone: person.phone,
      custom_attributes: custom_attributes,
      autocompleteLock: true,
      autocompleteField: "" // Close the AutoComplete modal
    })
  }
  else if (value === "no") {
    this.rejectAutocompletePerson()
  }

  event.preventDefault();
}

export function rejectAutocompletePerson() {
  this.setState((prevState) => ({
    "autocompletePerson": "",
    "autocompleteField": "",
    "rejectedAutocompletePerson": prevState.autocompletePerson,
    "autocompleteLock": false
  }));
}

export function handlePersonChange(event) {
  let name = event.target.name;
  let value = event.target.value;
  this.setState({[name]: value});

  if (this.state.autocompleteLock) {
    return
  }

  this.setState({"autocompletePerson": ""});
  this.setState({"autocompleteField": ""});

  let person = selectPersonThroughAutocompletion(name, value, this)

  if (person) {
    this.setState({"autocompletePerson": person});
    this.setState({"autocompleteField": name});
  }
}

export function handlePlannedEventSubmit(event) {
  let person = this.state.autocompletePerson
  savePersonDetails(this, directionHandler(this), directionPluralHandler(this), person);

  event.preventDefault();
}

export function savePersonDetails(_this, direction, directions, person) {
  if (_this.state.autocompletePerson === "" && _this.props.location.state.profile.disable_auto_create === true) {
    // If AutoCreate is disabled and the person isn't checking in through AutoComplete, block them from proceeding.
    _this.setState({"autoCreateMessage": true})
  }
  else {
    let app_id = generateId();
    _this.setState({"app_id": app_id})

    let details;

    //"direction" is a proxy for either an "arrival" or "departure" variable, depending on where this function is called. The directionCheckHandler autopopulates with eithier check_in_time or check_out_time in keeping with "direction" 
    if (person) {
      // grab details from person passed in by autocomplete.
      details = {
        [directionCheckHandler(_this)]: formatDate(new Date()),
        person_id: person.id,
        first_name: _this.state.autocompleteLock ? _this.state.first_name : person.first_name,
        last_name: _this.state.autocompleteLock ? _this.state.last_name : person.last_name,
        email: _this.state.autocompleteLock ? _this.state.email : person.email,
        phone: _this.state.autocompleteLock ? _this.state.phone : person.phone,
        type_of_person: _this.props.location.state.profile.type_of_person,
        profile_id: _this.props.location.state.profile.id,
        app_id: app_id,
        version: process.env.REACT_APP_COMMIT_HASH
      }
    }
    else {
      // grab details from state instead
      details = {
        [directionCheckHandler(_this)]: formatDate(new Date()),
        first_name: _this.state.first_name,
        last_name: _this.state.last_name,
        email: _this.state.email,
        phone: _this.state.phone,
        type_of_person: _this.props.location.state.profile.type_of_person,
        profile_id: _this.props.location.state.profile.id,
        app_id: app_id,
        version: process.env.REACT_APP_COMMIT_HASH
      }
    }

    if (direction === "arrival") {
      details.planned_event_ids = _this.state.planned_event_ids
      saveCustomAttributes(_this.state.custom_attributes, app_id) // Save Custom Attribute data if required ones were requested on the Arrival page
    }

    takePhotoIfRequired(_this, app_id);

    //directions is a pluralized form of "direction" to allow data to flow properly from "arrival" or "departure" into "arrivals" or "departures" respectively.
    directions = JSON.parse(localStorage.getItem(directions))

    // Create arrivals/departures array if it doesn't exist
    if (directions === null) {
      directions = []
    }

    // Append to arrivals/departures array, and save to semi-permanent storage
    directions.push(details)
    //directionPluralHandler is another way of grabbing "arrivals"/"departures" once "directions" stops returning a string and instead returns an array
    localStorage.setItem(directionPluralHandler(_this), JSON.stringify(directions))

    _this.setState({validDetails: true})
  }
}

export function saveCustomAttributes(custom_attributes, arriving_app_id) {
  if (custom_attributes === undefined || Object.keys(custom_attributes).length === 0) {
    return
  }

  let customAttributes = JSON.parse(localStorage.getItem("custom_attributes"))

  // Create custom_attributes array if it doesn't exist
  if (customAttributes === null) {
    customAttributes = []
  }

  // Loop through custom_attributes looking for a matching arriving_app_id
  const index = customAttributes.findIndex(attribute => attribute.arriving_app_id === arriving_app_id);

  if (index !== -1) {
    // Existing custom_attribute found. Appending new data.
    customAttributes[index].custom_attributes = {
      ...customAttributes[index].custom_attributes,
      ...custom_attributes
    };
  } else {
    // Existing custom_attribute not found. Creating new data.
    const customAttribute = {
      "custom_attributes": custom_attributes,
      "arriving_app_id": arriving_app_id,
      "app_id": generateId(),
      "version": process.env.REACT_APP_COMMIT_HASH,
      "created_at": formatDate(new Date())
    }

    customAttributes.push(customAttribute)
  }

  // Append to custom_attributes array, and save to semi-permanent storage
  localStorage.setItem("custom_attributes", JSON.stringify(customAttributes))
}

export function takePhotoIfRequired(_this, app_id) {
  if (_this.state.photoRequired) {
    if (_this.state.autocompletePerson.photo_opt_out) {
      return _this.setState({ photoRequired: false })
    }

    const video = document.getElementById('video-container');
    const canvas = document.getElementById('canvas-container');

    let width = 320;
    let height = (video.videoHeight / video.videoWidth) * width;

    video.setAttribute("width", width);
    video.setAttribute("height", height);
    canvas.setAttribute("width", width);
    canvas.setAttribute("height", height);

    const context = canvas.getContext("2d");
    canvas.width = width;
    canvas.height = height;
    context.drawImage(video, 0, 0, width, height);

    const data = canvas.toDataURL("image/png");
    if (data !== "data:,") {
      localStorage.setItem(`${_this.state.direction}photo${app_id}`, data.replace(/^data:image\/(png|jpg);base64,/, ""))
    }

    _this.setState({photoCaptured: true})
  }
}

export function cleanupVideoStream(stream) {
  if (stream === undefined) {
    return
  }

  stream.getTracks().forEach((track) => {
    track.stop();
  });
}

export function photoRequired() {
  if (this.props.location.state === undefined) {
    return false
  }

  // take no photos if the device's local storage is overloaded
  if (localStorage.getItem("too_many_photos")){
    return false
  }

  const photo_required = this.props.location.state.profile[`${this.state.direction}_photo_required`]

  // always take a photo for "always", take a photo 1/10 times for "sometimes"
  return photo_required === "always" || (photo_required === "sometimes" && Math.random() * 10 <= 1)
}

export function handlePersonSubmit(event) {
  if (this.validator.allValid()) {

    let arriving_planned_events = []
    let hosting_planned_events = []

    if (this.state.direction === "arriving" && this.state.autocompletePerson) {
      [arriving_planned_events, hosting_planned_events] = findPlannedEvents(this.state.autocompletePerson)
    }

    if (arriving_planned_events.length === 0 && hosting_planned_events.length === 0) {
      savePersonDetails(this, directionHandler(this), directionPluralHandler(this), this.state.autocompleteLock && this.state.autocompletePerson);
    }
    else {
      this.setState({
        arriving_planned_events: arriving_planned_events,
        hosting_planned_events: hosting_planned_events
      })
    }
  }
  else {
    this.validator.showMessages();
    this.forceUpdate();
  }

  event.preventDefault();
}

export function fieldDetails(name, _this) {
  let person = _this.state.autocompletePerson

  if (name === "first_name" || name === "last_name") {
    return (
      <div className="value">
        Are you <strong>{person.full_name}?</strong>
      </div>
    )
  }

  if (name === "email") {
    return (
      <div className="value">
        Is this your email address?
        <br />
        <strong>{person.email}</strong>
      </div>
    )
  }

  if (name === "phone") {
    return (
      <div className="value">
        Is this your phone number?
        <br />
        <strong>{person.phone}</strong>
      </div>
    )
  }
}

export function displayAutocompletePersonForSelecting(name, _this) {
  if (_this.state.autocompleteField === name && (_this.state.direction === "leaving" || (_this.state.hosting_planned_events.length === 0 && _this.state.arriving_planned_events.length === 0))) {
    return (
      <div className="autocomplete-container">
        <div className="autocomplete-content">
          {fieldDetails(name, _this)}
          <button className="autocomplete-button" value="yes" onClick={_this.handleAutocomplete}>Yes</button>
          <button className="autocomplete-button" value="no" onClick={_this.handleAutocomplete}>No</button>
        </div>
      </div>
    )
  }
}

export function displayAutocreateNotAllowedMessage(_this) {
  const typeOfPerson = _this.props.location.state.profile.type_of_person
  const titleCaseTypeOfPerson = typeOfPerson.charAt(0).toUpperCase() + typeOfPerson.slice(1);

  if (_this.state.autoCreateMessage === true) {
    return (
      <div className="popup-container">
        <div className="popup-content">
          <p>The details you entered do not match anyone on the system.</p>
          <p>Please return to the home screen and try again.</p>

          <div className="popup-padding">
            <Link to={{ pathname: '/'}} className="popup-button">
              Home
            </Link>
          </div>

          <div className="popup-small">The {titleCaseTypeOfPerson} profile does not allow AutoCreate.</div>
        </div>
      </div>
    )
  }
}
