import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import Cookies from 'universal-cookie';
import SimpleReactValidator from 'simple-react-validator';

import HeaderText from '../../components/communal/HeaderText';
import InteractionTimeout from '../../components/communal/InteractionTimeout';
import SetTitle from '../../components/communal/SetTitle';
import DestinationSwitch from '../../components/shared/DestinationSwitch';
import PersonFields from '../../components/shared/PersonFields';

import { handleAutocomplete, rejectAutocompletePerson, handlePersonChange, handlePersonSubmit, displayAutocompletePersonForSelecting, displayAutocreateNotAllowedMessage, photoRequired, cleanupVideoStream, backgroundMatchPerson } from '../../utilities/communal/Forms.js'

class Leaving extends Component {

  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator();

    this.photoRequired = photoRequired.bind(this);
    this.handleAutocomplete = handleAutocomplete.bind(this);
    this.rejectAutocompletePerson = rejectAutocompletePerson.bind(this);
    this.handlePersonChange = handlePersonChange.bind(this);
    this.handlePersonSubmit = handlePersonSubmit.bind(this);
  }

  state = {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",

    autocompletePerson: {},
    autocompleteField: "",
    autocompleteLock: false,
    rejectedAutocompletePerson: "",

    autoCreateMessage: false,

    photoRequired: false,
    photoCaptured: null,

    videoStreams: [],

    validDetails: false,
    errors: "",
    error: "",

    // Very important for `directionHandler()`. Do not remove.
    direction: "leaving"
  }

  render() {
    const cookies = new Cookies()
    const token = cookies.get('device_token')

    if (token === undefined) {
      return <Redirect to="/setup/user"/>
    }

    if (this.props.location.state === undefined) {
      return <Redirect to="/404" />
    }

    let profile = this.props.location.state.profile
    let person = this.state.autocompletePerson

    const { first_name, last_name, email, phone, direction, autocompletePerson, autocompleteLock } = this.state;

    if (this.state.validDetails) {
      // wait until photo is taken if it is needed
      if (this.state.photoRequired === false || this.state.photoCaptured === true) {
        if (typeof person === "string") {
          person = backgroundMatchPerson("feedback_answer_times", this)
        }

        return <DestinationSwitch current_page="leaving" profile={profile} leaving_app_id={this.state.app_id} direction="leaving" person={person} />
      }
    }

    const flow_order = localStorage.flow_order || "profiles"

    return (
      <div className="main-page arriving-page back-button">
        <InteractionTimeout seconds={60} />

        <SetTitle title={"Leaving"} />
        <HeaderText />

        <div className="content">
          <div className="widget form">
            <form autoComplete="off" onSubmit={this.handlePersonSubmit}>
              <div className="message">
                {profile.text_on_check_out}
              </div>

              {autocompleteLock &&
                <div className="message">
                  Leaving under {autocompletePerson.full_name}. <button className="autocomplete-button" onClick={this.rejectAutocompletePerson}>Not You?</button>
                </div>
              }

              <PersonFields
                profile={profile} personal={false}
                first_name={first_name} last_name={last_name} email={email} phone={phone}
                handleChange={this.handlePersonChange} validator={this.validator} autocompleteLock={autocompleteLock}
              />

              <br />
              <input type="submit" value="Submit" />
            </form>
            <video id="video-container" className="hidden" />
            <canvas id="canvas-container" className="hidden" />

            {displayAutocompletePersonForSelecting("first_name", this)}
            {displayAutocompletePersonForSelecting("last_name", this)}
            {displayAutocompletePersonForSelecting("email", this)}
            {displayAutocompletePersonForSelecting("phone", this)}

            {displayAutocreateNotAllowedMessage(this)}
          </div>
        </div>

        <div className="back">
          {flow_order === "profiles" ?
            <Link to={{ pathname: '/arriving-or-leaving', state: { profile: profile } }} className="widget">Back</Link>
          :
            <Link to={{ pathname: '/profiles', state: { direction: direction } }} className="widget">Back</Link>
          }
        </div>
      </div>
    );
  }

  componentDidMount() {
    if (this.photoRequired()) {
      this.setState({ photoRequired: true });

      const video = document.getElementById('video-container');

      if (!video.srcObject) {
        navigator.mediaDevices.getUserMedia({ video:true })
          .then((stream) => {
            // permission obtained, no need to track previous rejections!
            localStorage.removeItem("rejectedPhotos");

            this.setState(prevState => {
              let streams = [...prevState.videoStreams]
              streams.push(stream)
              return ({videoStreams: streams})
            })

            video.srcObject = stream;

            // Needed for Apple devices
            video.play()
              .catch(error => {})
          })
          .catch((error) => {
            if (error.message.includes("not allowed")) {
              // if permission is rejected, we keep a running count of photos not taken due to rejection
              let rejectedPhotos = JSON.parse(localStorage.getItem("rejectedPhotos")) || 0;
              localStorage.setItem("rejectedPhotos", rejectedPhotos + 1)
            }
          })
      }
    }
  }

  componentWillUnmount() {
    this.state.videoStreams.forEach((stream) => {
      cleanupVideoStream(stream)
    })
  }
}

export default Leaving;
