import React from 'react';
import Cookies from 'universal-cookie';

import { generateId, formatDate } from '../../utilities/generic/Common.js'
import { directionHandler, directionCheckHandler } from '../../utilities/generic/Forms.js'

export function handleAutocomplete(event) {
  let value = event.target.value;

  if (value === "yes") {
    // set person details in state
    let person = this.state.autocompleteDetails

    let custom_attributes = {}

    person.custom_attributes && person.custom_attributes.forEach((person_custom_attribute) => {
      custom_attributes[person_custom_attribute.id] = person_custom_attribute.value
    })

    this.setState({
      first_name: person.first_name,
      last_name: person.last_name,
      email: person.email,
      phone: person.phone,
      custom_attributes: custom_attributes
    });
  }
  else if (value === "no") {
    let cookies = new Cookies()
    cookies.remove('person_token', { path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN });
  }

  this.setState({"autocompleteDetails": {}});
  this.setState({"autocomplete": false});

  event.preventDefault();
}

export function handlePersonSubmit(event) {
  if (this.validator.allValid()) {
    // If AutoCreate is disabled and the person doesn't exist on the system, block them from proceeding.
    // On the communal flow we look at AutoComplete, but for personal flow we need to talk to the API.
    // Otherwise no one would be able to generate a person_token the first time!
    if (this.props.location.state.profile.disable_auto_create === true) {
      checkIfAutocreateAllowed(this)
    }
    else {
      if (this.state.direction === "arriving") {
        handlePlannedEventSubmit(this)
      }
      else {
        postEvent(this)
      }
    }
  }
  else {
    this.validator.showMessages();
    this.forceUpdate();
  }

  event.preventDefault();
}

export function checkIfAutocreateAllowed(_this) {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");

  const json = JSON.stringify({
    "personal": {
      "token": _this.props.location.state.token,
      "first_name": _this.state.first_name,
      "last_name": _this.state.last_name,
      "email": _this.state.email,
      "phone": _this.state.phone,
      "type_of_person": _this.props.location.state.type_of_person || _this.props.location.state.profile.type_of_person,
      "profile_id": _this.props.location.state.profile.id
    }
  })

  const requestOptions = {
    method: 'POST',
    headers: headers,
    body: json,
    redirect: 'follow'
  };

  let errorsInResponse = false

  fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/d/personal/person_lookup`, requestOptions)
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      else if (response.status === 401 || response.status === 404) {
        errorsInResponse = true
        return response.json();
      }
      else {
        throw new Error('Something went wrong ...');
      }
    })
    .then(data => {
      if (errorsInResponse) {
        _this.setState({"autoCreateMessage": true})
      }
      else {
        if (_this.state.direction === "arriving") {
          handlePlannedEventSubmit(_this)
        }
        else {
          postEvent(_this)
        }
      }
    })
    .catch(error => _this.setState({ error: error, loaded: true }))
}

export function handlePlannedEventSubmit(_this) {
  let token = _this.props.location.state.token

  let cookies = new Cookies()
  let person_token = cookies.get('person_token')

  var headers = new Headers();
  headers.append("Content-Type", "application/json");

  var json = JSON.stringify({
    "personal": {
      "token": token,
      "device_time": formatDate(new Date()),
      "first_name": _this.state.first_name,
      "last_name": _this.state.last_name,
      "email": _this.state.email,
      "phone": _this.state.phone,
      "person_token": person_token,
      "profile_id": _this.props.location.state.profile.id,
      "type_of_person": _this.props.location.state.profile.type_of_person
    }
  })

  var requestOptions = {
    method: 'POST',
    headers: headers,
    body: json,
    redirect: 'follow'
  };

  var errorsInResponse = false

  fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/d/personal/planned_events`, requestOptions)
  .then(response => {
    if (response.ok) {
      return response.json();
    }
    else if (response.status === 401 || response.status === 422) {
      errorsInResponse = true
      return response.json()
    }
    else {
      throw new Error('Something went wrong ...');
    }
  })
  .then(data => {
    if (errorsInResponse) {
      _this.setState({ errors: data })
    }
    else if (data.arriving_planned_events.length === 0 && data.hosting_planned_events.length === 0) {
      postEvent(_this)
    }
    else {
      _this.setState({
        arriving_planned_events: data.arriving_planned_events,
        hosting_planned_events: data.hosting_planned_events,
        errors: ""
      })
    }
  })
  .catch(error => _this.setState({ error, loaded: true }))
}

export function submitEvent(event) {
  if (this.validator.allValid()) {
    postEvent(this)
  }
  else {
    this.validator.showMessages();
    this.forceUpdate();
  }

  event.preventDefault();
}

export function postEvent(_this) {
  let token = _this.props.location.state.token

  let cookies = new Cookies()
  let person_token = cookies.get('person_token')

  let app_id = generateId();
  _this.setState({"app_id": app_id})

  var headers = new Headers();
  headers.append("Content-Type", "application/json");

  var json = JSON.stringify({
    "personal": {
      "token": token,
      "device_time": formatDate(new Date()),
      //The directionCheckHandler autopopulates with eithier check_in_time or check_out_time depending on what direction the person is heading in
      [directionCheckHandler(_this)]: formatDate(new Date()),
      "first_name": _this.state.first_name,
      "last_name": _this.state.last_name,
      "email": _this.state.email,
      "phone": _this.state.phone,
      "planned_event_ids": _this.state.planned_event_ids,
      "person_token": person_token,
      "app_id": app_id,
      "profile_id": _this.props.location.state.profile.id,
      "type_of_person": _this.props.location.state.profile.type_of_person,
      "version": process.env.REACT_APP_COMMIT_HASH
    }
  })

  var requestOptions = {
    method: 'POST',
    headers: headers,
    body: json,
    redirect: 'follow'
  };

  var errorsInResponse = false
  //The directionHandler finishes the url with either "arrival" or "departure" depending on the direction the person is heading in
  fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/d/personal/${directionHandler(_this)}`, requestOptions)
  .then(response => {
    if (response.ok) {
      return response.json();
    }
    else if (response.status === 401 || response.status === 422) {
      errorsInResponse = true
      return response.json()
    }
    else {
      throw new Error('Something went wrong ...');
    }
  })
  .then(data => {
    if (errorsInResponse) {
      _this.setState({ errors: data })
    } else {
      const cookies = new Cookies();
      cookies.set('person_token', data.cookie, { path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN });

      if (_this.state.direction === "arriving") {
        // Submit required Custom Attributes now
        handleCustomAttributes(_this, _this.state.custom_attributes, app_id)

        // Custom Attributes, Relationships and Covid status are only taken into account on a person's arrival, so if they are not arriving then relationship handling cannot be included
        _this.setState({
          updated: true,
          custom_attributes: data.custom_attributes,
          relationships: data.relationships,
          covid_pass_expiry_date: data.covid_pass_expiry_date,
          covid_pass_exemption: data.covid_pass_exemption,
          feedback_answer_times: data.feedback_answer_times,
          errors: ""
        })
      }
      else {
        _this.setState({
          updated: true,
          feedback_answer_times: data.feedback_answer_times,
          errors: ""
        })
      }
    }
  })
  .catch(error => _this.setState({ error, loaded: true }))
}

export function handleCustomAttributes(_this, custom_attributes, arriving_app_id) {
  if (Object.entries(custom_attributes).length === 0) {
    return
  }

  // Data sometimes comes through as an array.
  // Reformatting it into an object should avoid errors
  if (Array.isArray(custom_attributes)) {
    let custom_attributes_array = custom_attributes
    custom_attributes = {}

    custom_attributes_array.forEach((custom_attribute) => {
      custom_attributes[custom_attribute.id] = custom_attribute.value
    })
  }

  var headers = new Headers();
  headers.append("Content-Type", "application/json");

  let json = JSON.stringify({
    "personal": {
      "token": _this.props.location.state.token,
      "arriving_app_id": arriving_app_id,
      "custom_attributes": custom_attributes,
      "version": process.env.REACT_APP_COMMIT_HASH,
      "created_at": formatDate(new Date()),
      "profile_id": _this.props.location.state.profile.id,
      "type_of_person": _this.props.location.state.profile.type_of_person
    }
  })

  var requestOptions = {
    method: 'POST',
    headers: headers,
    body: json,
    redirect: 'follow'
  };

  var errorsInResponse = false;

  fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/d/personal/custom_attributes`, requestOptions)
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      else if (response.status === 401 || response.status === 422) {
        errorsInResponse = true
        return response.json()
      }
      else {
        throw new Error('Something went wrong ...');
      }
    })
    .then(data => {
      if (errorsInResponse) {
        _this.setState({ errors: data })
      } else {
        _this.setState({ updated: true, errors: ""})
      }
    })
    .catch(error => _this.setState({ error }))
}

export function prepositionHandler(_this) {
  if(_this.state.direction === "arriving") {
    return "in"
  }
  else {
    return "out"
  }
}

export function displayAutocompletePersonForSelecting(_this) {
  if (_this.state.autocomplete === true) {
    return (
      <div className="autocomplete-container">
        <div className="autocomplete-content">
          <div className="value">
            Check {prepositionHandler(_this)} as <strong>{_this.state.autocompleteDetails.full_name}?</strong>
          </div>

          <button className="autocomplete-button" value="yes" onClick={_this.handleAutocomplete}>Yes</button>
          <button className="autocomplete-button" value="no" onClick={_this.handleAutocomplete}>No</button>
        </div>
      </div>
    )
  }
}

export function displayAutocreateNotAllowedMessage(_this) {
  const typeOfPerson = _this.props.location.state.profile.type_of_person
  const titleCaseTypeOfPerson = typeOfPerson.charAt(0).toUpperCase() + typeOfPerson.slice(1);

  if (_this.state.autoCreateMessage === true) {
    return (
      <div className="popup-container">
        <div className="popup-content">
          <p>The details you entered do not match anyone on the system.</p>
          <p>Please scan the QR code and try again.</p>

          <div className="popup-small">The {titleCaseTypeOfPerson} profile does not allow AutoCreate.</div>
        </div>
      </div>
    )
  }
}
