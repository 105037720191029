import React, { Component } from 'react';

import { requiredString, renderRequired } from '../../utilities/generic/Forms.js'

class PersonFields extends Component {
  render() {
    const { personal, profile, validator, first_name, last_name, email, phone, custom_attributes, on_arrival_custom_attributes, autocompleteLock, handleChange, handleCustomAttributeChange} = this.props;

    return (
      <>
        { profile.first_name_used &&
          <label className="bottom-margin">
            <div className="text">{(profile.first_name_called) ? profile.first_name_called : "First Name"}{renderRequired(profile.first_name_required)}</div>
            <input aria-required="true" type="text" name="first_name" value={first_name} autoComplete={personal ? "given-name" : "off"} onChange={handleChange} />
            <div className="blank"></div>
            {validator.message('first_name', first_name, requiredString(profile.first_name_required, "string"), { className: 'error' })}
          </label>
        }

        { profile.last_name_used &&
          <label className="bottom-margin">
            <div className="text">{(profile.last_name_called) ? profile.last_name_called : "Last Name"}{renderRequired(profile.last_name_required)}</div>
            <input aria-required="true" type="text" name="last_name" value={last_name} autoComplete={personal ? "family-name" : "off"} onChange={handleChange} />
            <div className="blank"></div>
            {validator.message('last_name', last_name, requiredString(profile.last_name_required, "string"), { className: 'error' })}
          </label>
        }

        { profile.email_used &&
          <label className="bottom-margin">
            <div className="text">{(profile.email_called) ? profile.email_called : "Email Address"}{renderRequired(profile.email_required)}</div>
            <input aria-required="true" type="email" name="email" value={email} autoComplete={personal ? "email" : "off"} onChange={handleChange} />
            <div className="blank"></div>
            {validator.message('email', email, requiredString(profile.email_required, "email"), { className: 'error' })}
          </label>
        }

        { profile.phone_used &&
          <label className="bottom-margin">
            <div className="text">{(profile.phone_called) ? profile.phone_called : "Phone Number"}{renderRequired(profile.phone_required)}</div>
            <input aria-required="true" type="tel" name="phone" value={phone} autoComplete={personal ? "tel" : "off"} onChange={handleChange} />
            <div className="blank"></div>
            {autocompleteLock === false ? (
              validator.message('phone', phone, requiredString(profile.phone_required, "phone"), { className: 'error' })
            ) : (
              // Skip "phone" validation when data is populated from autocomplete
              validator.message('phone', phone, requiredString(profile.phone_required, "string"), { className: 'error' })
            )}
          </label>
        }

        {on_arrival_custom_attributes && on_arrival_custom_attributes.map((attribute) => (
          <label className="bottom-margin" key={attribute.id}>
            <div className="text">{attribute.name}{renderRequired(true)}</div>
            <input aria-required="true" type="text" name={attribute.id} value={custom_attributes[attribute.id] || ""} autoComplete={personal ? "given-name" : "off"} onChange={handleCustomAttributeChange} />
            <div className="blank"></div>
            {validator.message(attribute.name, custom_attributes[attribute.id], requiredString(true, "string"), { className: 'error' })}
          </label>
        ))}

        <div className="red">* Required fields.</div>
      </>
    )
  }
}

export default PersonFields;
